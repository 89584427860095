import { RegistroAcoesService } from './../../../../../service/registro-acoes/registro-acoes.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-consultar-usuarios',
  templateUrl: 'consultar-usuarios.component.html',
  styleUrls: ['./consultar-usuarios.component.scss']
})
export class ConsultarUsuariosComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;
  @Input() itemsPerView: number = 10;

  searchParams: any;
  searchParam: any;
  list: Array<any> = [];
  data: Object = {};
  usuarios: Array<any> = [];
  subscription: Subscription;
  labels: any;
  infoError: any;
  infoHistorico: any;
  showFilter = false;
  historicoEncontrado = false;
  perfilAcesso = '';
  loading: boolean = false;
  numeroUsuarios: string = '10';
  filterCurrent: Object = {
    pagina: 1,
    total_pagina: 10
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    private gerenciarUsuariosService: GerenciarUsuariosService,
    private registroAcoesService: RegistroAcoesService
  ) {
    this.data = [];
    this.setLabels();
  }

  ngOnInit() {
    this.subscription =
      this.gerenciarUsuariosService.usuarios$.subscribe(
          (res) => {
            this.data = res;
            // this.onPageChange(this.filterCurrent);
            this.filterCurrent = this.child.searchParams;
            this.usuarios = res["resultado"];
            this.data['totalDeItems'] = res.totalRegistros;
            this.data['totalPaginas'] = res['totalPaginas'];
            this.data['primeiraPagina'] = res.primeiraPagina;
            this.data['ultimaPagina'] = res.ultimaPagina;
            this.loading = false;
            console.log(res);
          }
        );
        // this.getUsers()
  }

  updateSearchParams(params) {
    this.searchParams = params;
  }

  filterProfile(profile) {
    this.showFilter = false;
    this.perfilAcesso = profile;
  }

  exportarUsuarios(tipoDocumento: string, file: string) {
    Swal.fire({
      text: 'Deseja baixar a lista de usuários?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(res => {
      if(res.value) {
        this.loading = true;
        this.searchParams['tipoDocumento'] = tipoDocumento;
        this.gerenciarUsuariosService
          .exportarUsuarios(this.searchParams)
          .subscribe((res: any) => {
            this.loading = false;
            const blob = new Blob([res], { type: `application/${file}` });
            FileSaver.saveAs(blob, `usuarios.${file}`);
            }, error => {
            Swal.fire(
              getSwal(
                'error',
                'Ops!',
                'Ocorreu um erro e não foi possível baixar a lista de usuários',
                'ok',
                null,
              ),
            );
            this.loading = false;
          });
      }
    });
  }

  exportarDadosUsuarios(file: string, dados: any) {
    const codigo = dados.usuarioCliente.usuario.codigo; 
    this.gerenciarUsuariosService
      .obterUsuariosExportar(codigo)
      .subscribe((res: any) => {
        const blob = new Blob([res], { type: `application/${file}` });
        FileSaver.saveAs(blob, `ativacao.${file}`);
      }, err => {
        const blob = new Blob([err.error.text], { type: `application/${file}` });
        FileSaver.saveAs(blob, `ativacao.${file}`);
      });
  }

  getUsers() {
    this.loading = true;
      let parametros = {};
      parametros['pagina'] = this.data['paginaAtual'];
      parametros['total_pagina'] = this.itemsPerView;
      if (this.child.searchParams.codigoPerfilAcesso) {
        parametros['codigoPerfilAcesso'] = this.child.searchParams.codigoPerfilAcesso;
      } else {
        delete parametros['codigoPerfilAcesso'];
      }
      // this.codigoPerfilAcesso ? parametros['codigoPerfilAcesso'] = this.child.searchParams.codigoPerfilAcesso : delete parametros['codigoPerfilAcesso'];
      if (this.child.searchParams.situacao) {
        parametros['situacao'] = this.child.searchParams.situacao;
      } else {
        delete parametros['situacao'];
      }
      if (this.child.searchParams.cpfCnpj) {
        parametros['cpfCnpj'] = this.child.searchParams.cpfCnpj;
      } else {
        delete parametros['cpfCnpj'];
      }
      if (this.child.searchParams.inscricao) {
        parametros['inscricao'] = this.child.searchParams.inscricao;
      } else {
        delete parametros['inscricao'];
      }
      if (this.child.searchParams.nomeRazaoSocial) {
        parametros['nomeRazaoSocial'] = this.child.searchParams.nomeRazaoSocial;
      } else {
        delete parametros['nomeRazaoSocial'];
      }
    this.gerenciarUsuariosService.obterUsuarios(parametros).subscribe(res => {
      this.loading = false;
        if(res.tamanhoPagina) {
            this.data = res;
            this.usuarios = res['resultado'];
            this.data['totalDeItems'] = res.totalRegistros;
            this.loading = false;
          }     
      }, error => {
        this.loading = false;
      });
  }

  next() {
    this.data['paginaAtual']++;
    this.onPageChange(this.filterCurrent);
  }

  previous() {
    this.data['paginaAtual']--;
    this.onPageChange(this.filterCurrent);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onPageChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onPageChange(this.filterCurrent);
  }

  onPageChange(value) {
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.itemsPerView;

    this.filterCurrent = value;
    this.loading = true;

    this.gerenciarUsuariosService.obterUsuarios(value).subscribe(
      res => {
        this.usuarios = res['resultado'];
        this.loading = false;
      },
      error => {
        this.loading = false;
        //this.aplicacoes = [];
      }
    )
  }

  quantidadeUsuarios(numeroUsuarios) {

    this.itemsPerView = +numeroUsuarios;
    
    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;
    this.filterCurrent['total_pagina'] = this.itemsPerView;
  
    this.onPageChange(this.filterCurrent);

    this.data['paginaAtual'] = 1;
    this.data['itemsPorPagina'] = this.itemsPerView;
  
    switch (numeroUsuarios) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
  
    this.loading = true;
    return this.getUsers()
  }

  edit(user: any) {
    this.gerenciarUsuariosService.setUser(user);
    this.router.navigate([
      `gestao-usuario/definir/${user.codigo}`
    ]);
  }

  detalhes(user: any) {
    this.gerenciarUsuariosService.setUser(user);
    this.registroAcoesService.getAtivacaoCadastro(user.codigo).subscribe(
      res => {
        this.historicoEncontrado = true;
        this.infoHistorico = res.resultado;
        console.log(this.infoHistorico);
        
      },
      err => {
        this.historicoEncontrado = false;
        this.infoError = err.error.excecao.mensagem;
      }
      
    );
  }

  toggleFilter($event) {
    this.showFilter = this.showFilter === $event ? '' : $event;
  }

  setLabels() {
    this.labels = {
      titulo: this.translate.instant('CONFIGURACOES.USUARIOS.TITULOCONSULTAR'),
      nomeRazaoSocial: this.translate.instant('GLOBAIS.NOMERAZAOSOCIAL'),
      inscricao: this.translate.instant('GLOBAIS.INSCRICAO'),
      cpfcnpj: this.translate.instant('GLOBAIS.CPFCNPJ'),
      acao: this.translate.instant('GLOBAIS.ACAO'),
      situacao: this.translate.instant('GLOBAIS.SITUACAO'),
      ativo: this.translate.instant('GLOBAIS.ATIVO'),
      inativo: this.translate.instant('GLOBAIS.INATIVO'),
      novalidated: this.translate.instant('GLOBAIS.SEMVALIDACAO'),
      pagina: this.translate.instant('GLOBAIS.PAGINA'),
      semresultado: this.translate.instant('GLOBAIS.SEMRESULTADO'),
      novo: this.translate.instant('CONFIGURACOES.USUARIOS.INSERIRNOVO'),
      graf_acessos: this.translate.instant('CONFIGURACOES.USUARIOS.GRAFICOACESSOS'),
      graf_perfil: this.translate.instant('CONFIGURACOES.USUARIOS.GRAFICOPERFIL'),
      graf_procuracoes: this.translate.instant(
        'CONFIGURACOES.USUARIOS.GRAFICOPROCURACOES'
      ),

      graf_situacoes: this.translate.instant(
        'CONFIGURACOES.USUARIOS.GRAFICOSITUACOES'
      )
    };
  }
}
