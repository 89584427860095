import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../service/user-service/user.service'
import { ActivatedRoute, NavigationEnd, Router, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})

export class BreadcrumbComponent implements OnInit {
  config: any = null;
  breadcrumbs: Array<Object> = [];

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.config = this.router.config;
    this.router.events
      .pipe( filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.breadcrumbs = [];
        let currentRoute = this.route.root, url = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(route => {
            if (route.outlet === 'primary') {
              const routeSnapshot = route.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              this.userService.refreshUrl(url);
              let label = null;
              if (route.snapshot.data.breadcrumb) {
                label = route.snapshot.data.breadcrumb;
                this.breadcrumbs.push({
                  label: label,
                  url: url
                });
              }
              currentRoute = route;
            }
          });
        } while (currentRoute);

        if (this.breadcrumbs.length <= 0) {
          this.breadcrumbs.push({
            label: 'Página Inicial',
            url: '/home'
          })
        }
      });
  }

}
