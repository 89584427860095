import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import { getSwal } from '../../../../../utils/swalObject';
import { ComunicadosService } from '../../../../../service/comunicados/comunicados.service';
import { CpfCnpjPipe } from '../../../../../pipes/cpf-cnpj.pipe';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
  selector: '[app-messages-list]',
  templateUrl: 'messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesComponent implements OnInit, AfterViewInit {
  @Input() view = 0;

  subscription: Subscription;
  selected: number = null;
  mensagens: any = [];
  loading: boolean = true;
  usuario: Object = null;
  dataContribuinte: Object = null;
  exibirPopUpContribuinte: boolean = false;
  enviarMensagem: boolean = false;
  escreverMensagem: boolean = false;
  detalhesLeitura: boolean = false;
  tipoComunicado: Object = null;
  podeEnviarComunicados: boolean;
  comunicadosList: Array<any> = [];
  dadosLeitura: Array<any> = [];
  filtroMensagens: string = '';
  // Paginator:
  itemsPerView: number = 5;
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  filterCurrent: Object = {
    pagina: 1,
    totalPagina: 10
  };
  filterVisible: number = 0;
  index: number;
  numeroComunicados: string = "10";
  cpfCnpjUsuario: string = '';

  constructor(
    private caixaPostalService: CaixaPostalService,
    private route: ActivatedRoute,
    private router: Router,
    private comunicadosService: ComunicadosService,
    private docPipe: CpfCnpjPipe
  ) {}

  ngOnInit() {
    if(this.route.paramMap){
      this.route.paramMap.subscribe(params => {
        this.filtroMensagens = params.get('filtroMensagens');
      });
    }
    let usuario = JSON.parse(localStorage.getItem('session'));
    this.cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    sessionStorage.removeItem('filtro');

    this.obterComunicadosPlataformaDTe()
      .then(result => {
        return this.loadMensagens();
      })
      .then(result => {
        this.loading = false;
      })
      .catch(err => {
        console.log('error: ', err);
      });
  }

  ngAfterViewInit() {
    

  }

  obterComunicadosPlataformaDTe() {
    var parametros = {};

    parametros['pagina'] = this.data['paginaAtual'];
    parametros['total_pagina'] = 10;
    parametros['paginaAtual'] = 1;

    return new Promise<void>((resolve, reject) => {
      this.comunicadosService
        .obterComunicadosPlataformaDTe(parametros)
        .subscribe(
          res => {
            if (res.resultado.length !== 0) {
              this.podeEnviarComunicados = true;
              this.comunicadosList = res.resultado;
            }
            resolve();
          },
          err => {
            reject(err);
          }
        );
    });
  }

  loadMensagens() {
    this.loading =true;
    
    return new Promise<void>((resolve, reject) => {
      var parametros = {};
      parametros = Object.assign(parametros, JSON.parse(sessionStorage.getItem('filtro')));
      parametros['pagina'] = 1;
      parametros['paginaAtual'] = 1;
      parametros['total_pagina'] = this.data['itemsPorPagina'];
      if (this.filtroMensagens) {
        parametros['respondidas'] =
          this.filtroMensagens === 'comrespostas' ? true : false;
      }

      this.caixaPostalService.getMensagens(parametros).subscribe(
        res => {
          this.processGetMessagesResult(res);
          this.loading = false;
          resolve();
        },
        error => reject(error)
      );
    });
  }

  onFilterChange(value) {
    this.loading = true;
    value['pagina'] = this.data['paginaAtual'];
    value['total_pagina'] = this.data['itemsPorPagina'];
    value['paginaAtual'] = this.data['paginaAtual'] = 1

    if (this.filtroMensagens) {
      value['respondidas'] =
        this.filtroMensagens === 'comrespostas' ? true : false;
    }

    this.filterCurrent = value;
    this.loading = true;

    this.caixaPostalService.getMensagens(value).subscribe(
      data => {
        this.processGetMessagesResult(data);
        this.loading = false;
      },
      error => {
        this.loading = true;
        this.mensagens = [];
        // this.onDataChange.emit(false);
      }
    );
  }

  onPageUpdate(page: number) {
    console.log('Page updated to:', page); 
    this.loading = true;
    this.data['paginaAtual'] = 1; 
    this.loadMensagens(); 
  }

  handlePageUpdate(event: number) {
    this.data['paginaAtual'] = 1; 
    this.data['pagina'] = 1; 
  }

  iniciarComunicado() {
    this.filterVisible += 1;
    this.enviarMensagem = true;
  }

  processGetMessagesResult(res) {
    res.resultado.forEach(mensagem => {
      if (
        mensagem.destinatario.aplicacao === null &&
        mensagem.destinatario.usuario === null
      ) {
        console.log(mensagem.destinatario, ' << ', mensagem.protocolo);
      }
      mensagem.remetenteTipo =
        mensagem.remetente.aplicacao && mensagem.remetente.aplicacao !== null
          ? 'aplicacao'
          : 'usuario';

      mensagem.destinatarioTipo =
        mensagem.destinatario.aplicacao &&
        mensagem.destinatario.aplicacao !== null
          ? 'aplicacao'
          : 'usuario';

      mensagem.classDataEnvioIcon =
        mensagem.remetenteTipo === 'aplicacao'
          ? 'resposta-aplicacao'
          : 'resposta-usuario';

  
      mensagem.cienciaMensagem =
        mensagem.cienciaMensagem === null ? [] : mensagem.cienciaMensagem;

  
      mensagem.cienciaMensagem.forEach(cienciaMe => {
        if (cienciaMe.usuario) {
          cienciaMe.usuario.cpfCnpj = this.docPipe.transform(
            cienciaMe.usuario.cpfCnpj
          );
        }
      });

      mensagem.mensagemLimpa = this.cleanMessage(mensagem.mensagem);
    });
    this.mensagens = res.resultado;    
    // ----- REMETENTE -----
    this.mensagens.forEach((res, index) => {  
      if(this.mensagens[index].remetente.usuario === null) {
        this.mensagens[index].remetente = this.mensagens[index].remetente.aplicacao.descricao;
      } else {
        this.mensagens[index].remetenteCpfCnpj = this.mensagens[index].remetente.usuario.cpfCnpj;
        this.mensagens[index].remetente = this.mensagens[index].remetente.usuario.nomeRazaoSocial;
      }

      // ----- DESTINATARIO -----
      if(this.mensagens[index].destinatario.usuario === null) {
        this.mensagens[index].destinatarioNome = this.mensagens[index].destinatario.aplicacao.descricao;
      } else {
        this.mensagens[index].destinatarioNome = this.mensagens[index].destinatario.usuario.nomeRazaoSocial;
      }
      
      // ----- DATA DE ENVIO -----
      this.mensagens[index].dataEnvio = moment(this.mensagens[index].dataEnvio).format('DD/MM/YYYY');
      // ----- DATA DE LEITURA -----
      if(this.mensagens[index].leitura.length != 0) {
        this.mensagens[index].dataLeitura = moment(this.mensagens[index].leitura[0].dataCiencia).format('DD/MM/YYYY');        
      } else {
        this.mensagens[index].dataLeitura = ''
      }
      // ----- DATA DE CIÊNCIA -----
      if(this.mensagens[index].cienciaMensagem.length != 0) {
        if(this.mensagens[index].destinatario.aplicacao == null) {
          this.mensagens[index].cienciaMensagem.forEach((res) => {
            if(res.usuario.cpfCnpj == this.docPipe.transform(this.mensagens[index].destinatario.usuario.cpfCnpj)) {
              this.mensagens[index].dataCiencia = moment(res.dataCiencia).format('DD/MM/YYYY');
            }
          })
        } else {
          this.mensagens[index].dataCiencia = moment(this.mensagens[index].cienciaMensagem[0].dataCiencia).format('DD/MM/YYYY');
        }
      } else {
        this.mensagens[index].dataCiencia = '';
      }
      if(this.mensagens[index].tipoComunicado.prazoCiencia > 0) {
        console.log(this.mensagens[index].dataEnvio);
        let dias = this.mensagens[index].tipoComunicado.prazoCiencia;
        this.mensagens[index].dataLimite = moment(this.mensagens[index].dataEnvio, 'DD/MM/YYYY HH:mm:ss').add(dias, 'days').format('DD/MM/YYYY');
      } else {
        this.mensagens[index].dataLimite = '';
      }
    })
    this.data['totalDeItems'] = res.totalRegistros;
    
    if (Math.round(this.data['paginaAtual']) > Math.round(res.totalPaginas)) {
      this.data['paginaAtual'] = 1;
      // this.onFilterChange(this.filterCurrent);
    } else {
      this.data['totalPaginas'] = res.totalPaginas;
      this.data['paginaAtual'] = res.paginaAtual;
      this.data['primeiraPagina'] = res.primeiraPagina;
      this.data['ultimaPagina'] = res.ultimaPagina;
    }
  }

  next() {
    this.data['paginaAtual']++;
    this.onFilterChange(this.filterCurrent);
  }
  previous() {
    this.data['paginaAtual']--;
    this.onFilterChange(this.filterCurrent);
  }
  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onFilterChange(this.filterCurrent);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onFilterChange(this.filterCurrent);
  }

  cleanMessage(message) {
    return message
      .toLowerCase()
      .split('<br>')
      .join(' ');
  }

  setLoadingState(e: any) {
    this.loading = e;
  }

  getColor(tipoComunicado) {
    if (tipoComunicado.cor !== null) {
      return tipoComunicado.cor.cor;
    } else {
      return '#a1a1a1';
    }
  }

  getTextColor(tipoComunicado){
    if (tipoComunicado.cor !== null) {
      if(tipoComunicado.cor.cor === '#ffffff'){
        return '#000000';
      }
      return tipoComunicado.cor.cor === '#ffffff' ? '#000000' : '#ffffff';
    } else {
      return '#ffffff';
    }

  }

  openMessage(mensagem) {
    let destinatario;
    let leituraDestinatario;
    let leituraUsuario: boolean = false;

    if(mensagem.destinatario.length !== 0 && mensagem.destinatario.aplicacao == null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj;
    }

    if(mensagem.leitura.length !== 0){
      mensagem.leitura.forEach(res => {
        if(res.usuario.cpfCnpj == this.cpfCnpjUsuario){
          leituraUsuario = true;
        }
      })
    }

    if (mensagem.destinatario.aplicacao !== null && leituraUsuario) {
      this.marcarComoLida(mensagem);
    } else {
      this.router.navigate([`/caixa-postal/mensagem/${mensagem.protocolo}`]);
    }
  }

  marcarComoLida(mensagem) {
    let usuario = JSON.parse(localStorage.getItem('session'));
    let razaoSocialUsuario = usuario.usuario.nomeRazaoSocial;
    
    if (
      mensagem.situacaoMensagem.situacao === 0 &&
      mensagem.tipoComunicado.exigeAssinaturaDigital &&
      mensagem.remetente != razaoSocialUsuario
    ) {
      this.loading = false;
      this.abrirCertificado(mensagem.cienciaMensagem, mensagem.protocolo);
    } else {
      this.caixaPostalService.mensagemLida(mensagem.protocolo).subscribe(
        res => {
          this.router.navigate([`/caixa-postal/mensagem/${mensagem.protocolo}`]);
        },
        error => {
          console.log(error);
        }
      );
      
    }
  }

  abrirCertificado(cienciaMensagem, codigo) {
    if (cienciaMensagem.length == 0) {
      this.router.navigate([`/caixa-postal/certificados/${codigo}`]);
    } else {
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não é possível Dar Ciência para esta mensagem: ela já conta com ciência vigente',
          'ok',
          null
        )
      );
    }
  }

  arquivarMensagem(mensagem) {
    if (mensagem.situacaoMensagem.situacao !== 2) {
      this.caixaPostalService.arquivarMensagem(mensagem.protocolo).subscribe(
        res => {
          this.atualizarMensagens();
        },
        error => console.log(error)
      );
    }
  }

  gerarProtocolo(mensagem) {
    this.loading = true;
    this.caixaPostalService.gerarProtocolo(mensagem.protocolo).subscribe(
      response => {
        this.loading = false;
        var blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `dossie`);
      },
      error => {
        this.loading = false;
        Swal.fire(
          getSwal(
            'success',
            '',
            'Comunicado ' +
              mensagem.assunto +
              ' recebido em ' +
              // moment(new Date(mensagem.dataEnvio)).format('DD/MM/YYYY HH:mm:ss') +
              mensagem.dataEnvio +
              ' de número ' +
              mensagem.protocolo,
            'ok',
            null
          )
        );
      }
    );
  }

  atualizarMensagens() {
    var parametros = {};

    parametros['pagina'] = 1;
    parametros['total_pagina'] = 10;
    if (this.filtroMensagens) {
      parametros['respondidas'] =
        this.filtroMensagens === 'comrespostas' ? true : false;
    }

    this.caixaPostalService
      .getMensagens(parametros)
      .subscribe(res => console.log(res), error => console.log(error));
  }

  showData(index) {  
    Swal.fire({
      title: 'Responsável pela assinatura digital:',
      text: this.mensagens[index].cienciaMensagem[0].usuario.nomeRazaoSocial,
      icon: 'info',
      width: 600,
    });
  }

  abrirPopUpContribuinte(destinatario) {
    let cpfCnpj = null;
    this.loading = true;

    if (destinatario.usuario !== null) {
      cpfCnpj = destinatario.usuario.cpfCnpj;
      this.caixaPostalService.consultarContribuinte(cpfCnpj).subscribe(
        res => {
          this.loading = false;
          this.exibirPopUpContribuinte = true;
          this.dataContribuinte = res.resultado[0];
        },
        err => {
          this.loading = false;
          // Swal.fire('Ops!', err.error.excecao.mensagem, 'error');
          Swal.fire(
            getSwal('error', 'Ops!', err.error.excecao.mensagem, 'ok', null)
          );
        }
      );
    }
  }

  fecharPopUpContribuinte(event) {
    this.exibirPopUpContribuinte = event;
  }

  fecharPopUpLeitura(event) {
    this.detalhesLeitura = false;
  }

  fecharPopUpMensagem(event) {
    this.enviarMensagem = event;
    this.escreverMensagem = false;
    // Method to reload a messages list after sent a new message:
    this.loading = true;
    return this.loadMensagens()
      .then(result => {
        this.loading = false;
      })
      .catch(err => {
        console.log('error: ', err);
      });
  }

  abrirPopUpMensagem(event) {
    this.enviarMensagem = false;
    this.escreverMensagem = true;
    this.tipoComunicado = event[0];
  }

  quantidadeComunicados(numeroComunicados){

    this.data['paginaAtual'] = 1;
    switch (numeroComunicados) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
    this.loading = true;
    return this.loadMensagens()
    .then(result => {
      this.loading = false;
    });

  }

  corNovoComunicado(mensagem) {
    let usuario = JSON.parse(localStorage.getItem('session'));
    this.cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    let corComunicado;
    let destinatario;

    if(mensagem.destinatario.usuario !== null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj;
    }

    if(mensagem.leitura.length !== 0 && mensagem.respostas.length == 0) {
      if(mensagem.destinatario.aplicacao == null) {
        mensagem.leitura.forEach(res => {
          if(res.usuario.cpfCnpj == destinatario){
            corComunicado = "#e4e4e479";
          }
        })
      } else {
        mensagem.leitura.forEach(res => {
          if(res.usuario.cpfCnpj !== mensagem.remetenteCpfCnpj){
            corComunicado = "#e4e4e479";
          }
        })
      }
    } else if(mensagem.respostas.length !== 0) {
      corComunicado = this.verificarRespostas(mensagem.respostas)
    }

    return corComunicado;
  }

  verificarRespostas(resposta) {
    let corComunicado;
    let destinatario;
    
    resposta.forEach(res => {
      if(res.destinatario.usuario !== null) {
        destinatario = res.destinatario.usuario.cpfCnpj;
      }

      if(res.leitura.length !== 0 && res.respostas.length == 0) {
        res.leitura.forEach(leitura => {
          if(leitura.usuario.cpfCnpj == destinatario){
            corComunicado = '#e4e4e479';
          }
        })
      } else if(res.respostas.length == 0 && res.leitura.length == 0) {
        corComunicado = null;

      } else if(res.leitura.length == 0) {
        corComunicado = null;

      } else if(res.respostas.length !==0 ) {
        corComunicado = this.verificarRespostas(res.respostas);
      } 
    })

    return corComunicado
  }
    
}
     
