import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CaixaPostalService } from '../../../../../../../service/caixa-postal/caixa-postal.service';
import { SessionService } from '../../../../../../../service/session/session.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../../../utils/swalObject';
import 'summernote/dist/summernote';
import 'summernote/dist/summernote-bs4';
import { FormsModule } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-textbox-message',
  templateUrl: 'textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextBoxComponent implements OnInit {
  @Input('mensagem') mensagem: any;

  respostaForm: FormGroup;
  filesToUpload: Array<File>;
  config: any;
  mensagemString: string = '';
  files: Array<any> = [];
  respostaEnviada: boolean = false;

  constructor(
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService,
    private router: Router,
    private fb: FormBuilder,
    private elementRef: ElementRef 
  ) {
    this.filesToUpload = [];
  }

  ngOnInit() {
    this.criarFormulario();
    this.configToolBar();
    this.elementRef.nativeElement.ownerDocument.body.style.setProperty('charset', 'UTF-8');
    //this.mensagemString = (this.mensagem.innerHTML);
  }

  criarFormulario() {
    this.respostaForm = this.fb.group({
      resposta: ['', Validators.required]
    });
  }

  fileChangeEvent(fileInput: any) {
    // this.filesToUpload = <Array<File>>fileInput.target.files;
    const files = fileInput.target.files;
    for (let i = 0; i <= files.length - 1; i++) {
      this.filesToUpload.push(files[i]);
    }
  }

  validaUsuarioResponde(mensagem) {
    // funcao que verifica se o remetente é diferente do usuario a responder ou se o remetente é a aplicacao
    // caso algumas das condições seja verdadeira, o usuario poderá responder, caso contrário, não
    const session: Object = this.sessionService.getSession();
    const remetente = mensagem.remetente;
    if (mensagem.situacaoMensagem.situacao === 2) {
      return false;
    } else if (
      remetente.usuario &&
      remetente.usuario.cpfCnpj !== session['usuario'].cpfCnpj
    ) {
      return true;
    } else if (remetente.aplicacao !== null) {
      return true;
    } else {
      return false;
    }
  }

  responder() {
    const resposta = this.respostaForm.controls.resposta.value;
    const protocolo = Number(this.mensagem.protocolo);
    this.caixaPostalService
      .responderMensagem(protocolo, this.mensagemString, this.filesToUpload)
      .subscribe(
        res => {
          this.respostaForm.reset();
          this.filesToUpload = [];
          // Swal.fire(
          //   'Pronto!',
          //   'Sua resposta foi enviada com êxito.',
          //   'success'
          // );
          Swal.fire(
            getSwal(
              'success',
              'Pronto!',
              'Sua resposta foi enviada com êxito.',
              'ok',
              null
            )
          );
          this.router.navigate(['/caixa-postal']);
        },
        (
          error /*Swal.fire(
      'Ops!',
      'Ocorreu um erro e sua resposta não pôde ser enviada.',
      'error'
    )*/
        ) =>
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ocorreu um erro e sua resposta não pôde ser enviada.',
              'ok',
              null
            )
          )
      );
      this.respostaEnviada = true;
      console.log('this mensagem string', this.mensagemString)
  }

  removeFile(file) { 
    let files = []
    files = this.filesToUpload
    const newAnexos = [];
    this.files.forEach(attach => {
      if (attach.name !== file.name) {
        newAnexos.push(attach);
      }
    });
    this.filesToUpload = newAnexos;
  }

  configToolBar() {
  const those = this;
    this.config = {
      disabledDragAndDrop: true,
      disableResizeEditor: true,
      placeholder: "Escreva aqui...",
      tabsize: 2,
      height: '115px',
      width: '100%',
      toolbar: [["style", ["bold", "italic", "underline", "clear"]]],
      lang: "pt-BR",
      fontNames: [
        "Helvetica",
        "Arial",
        "Arial Black",
        "Courier New",
        "Roboto",
        "Times",
      ],
      callbacks: {
        onChange: function (e) {
          let caracteres = $(".note-editable").text().trim();
          let totalCaracteres = caracteres.length;
          // those.caracteresTextoPadrao = totalCaracteres;

        },
        onKeyup: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $("#maxContentPost").text(Math.max(0, 4000 - totalCaracteres));
        },
        onPaste: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var bufferText = (e.originalEvent || e).clipboardData.getData("Text");
        
          $(this).summernote("clear", e);
        
          $(".note-editable span").removeClass();

          $(".note-editable span, .note-editable span").removeAttr('style');

          $(".note-editable span, .note-editable p").removeAttr('style');
        
          e.preventDefault();
          var maxPaste = bufferText.length;
          if (totalCaracteres + bufferText.length > 4000) {
            maxPaste = 4000 - t.length;
          }
          if (maxPaste > 0) {
            document.execCommand(
              "insertText",
              false,
              bufferText.substring(0, maxPaste).trim()
            );
          }
        
          let novoTotalCaracteres = $(".note-editable")
            .text()
            .replace(/\s+/g, "").length;
          $("#maxContentPost").text(Math.max(0, 4000 - novoTotalCaracteres));
        },        
        onInput: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $('#maxContentPost').text(Math.max(0, 4000 - totalCaracteres));
      },
      },
    };
    $(".note-editable").css("font-size", "16px");
    $(".note-editable").css("font-family", "Calibri");
    $(".note-current-fontsize").text("14");
    $(".note-current-fontname").text("Calibri");
    $(".note-editable").css("font-weight", "normal");
    $(".popover > .arrow").css("display", "block");
    $(".note-hint-popover .popover-content").css("width", "max-content");
    $(".note-hint-popover").css("border", "none");
    $(".note-hint-popover .popover-content").css("position", "absolute");
  }
      
}
