import { OrganogramaService } from 'src/app/service/organograma/organograma.service';
import { environment } from './../../../../../environments/environment.hdte-3118601';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../service/user-service/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import 'rxjs/operators/filter';
import { PermissoesService } from '../../../../service/permissoes/permissoes.service';
import { SessionService } from './../../../../service/session/session.service';
//import { environment } from 'src/environments/environment'

@Component({
  selector: '[sidebar-menu]',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarMenuComponent implements OnInit {
  @Output() onSignOut = new EventEmitter<boolean>();
  subscription: Subscription;
  navSubscription: Subscription;
  sidebarFechada = false;

  links: Array<any> = [
    {
      title: 'Caixa Postal',
      route: '/caixa-postal',
      icon: '#caixa-postal-menu',
      modulo: '1001',
      visible: false,
    },
    {
      title: 'Gestão de Usuário',
      route: '/gestao-usuario',
      icon: '#team',
      modulo: '1010',
      visible: false,
    },
    {
      title: 'Análise de Dados',
      route: '/analise-dados',
      icon: '#monitor',
      modulo: '1009',
      visible: false,
      //powerBi: true,
    },
    {
      title: 'Configurar Comunicados',
      route: '/tipos-comunicados',
      icon: '#megaphone',
      modulo: '1007',
      visible: false,
    },
    // {
    //   title: 'Análise de Dados',
    //   route: environment.powerBi,
    //   icon: '#monitor',
    //   modulo: '1008',
    //   visible: false,
    //   powerBi: true,
    // },
    {
      title: 'Integração',
      route: '/integracao',
      icon: '#coding',
      modulo: '1006',
      visible: false,
    },
    {
      title: 'Histórico de Acessos',
      route: '/registro',
      icon: '#folder',
      modulo: '1002',
      visible: false,
    },
    {
      title: 'Configurações',
      route: '/configuracao',
      icon: '#cog',
      modulo: '1005',
      visible: false,
    },
  ];
  permissoes: Array<any> = null;
  userInfo;
  organograma;
  perfilAcesso: any;
  private selectedItem = 0;
  

  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private permissoesService: PermissoesService,
    private session: SessionService,
    private organogramaService: OrganogramaService
  ) {
    this.userInfo = this.session.getSession();
    this.subscription = this.userService.getInfo().subscribe(info => {
      this.sidebarFechada = info.sidebarFechada;
    });

    this.navSubscription = this.userService
      .getCurrentUrl()
      .subscribe(current => {
        const obj = this.links.reduce((prev, curr) => {
          return curr.route === current ? curr : prev;
        }, null);
        this.selectedItem = this.links.indexOf(obj);
      });
  }

  onItemSelected(link): void {
    this.selectedItem = this.links.indexOf(link);
    this.router.navigate([link.route]);
    // if(link.powerBi) {
    //   window.open(link.route);
    //   this.router.navigate(['home']);
    // } else {
    //   this.router.navigate([link.route]);
    // }
  }

  ngOnInit() {
    this.links.unshift({
      title: 'Página Inicial',
      route: '/home',
      icon: '#homepage',
      modulo: '0',
      visible: true,
    });
    
    this.perfilAcesso = this.userInfo.perfilAcesso.codigo;

    this.verificaOrganograma().then((res) => {
      this.organograma = res;
      this.permissoesService.obterPermissoes().subscribe(res => {
        this.permissoes = res.resultado;
        this.links.map(link => {
          const index = this.permissoes.findIndex(
            permissao => permissao.modulo === link.modulo,
          );
          if (link.title === 'Página Inicial' || index !== -1 ) {
            if((link.title === 'Análise de Dados' && this.organograma == true) || this.perfilAcesso == 0) {
              link['visible'] = true;
            } else if(link.title !== 'Análise de Dados') {
              link['visible'] = true;
            }
          } else {
            link['visible'] = false;
          }
        });
      });
    })
  }

  signOut() {
    this.userService.sendInfo({ loggedIn: false }).then(res => {
      this.session.clearSession();
      this.onSignOut.emit(false);
      this.router.navigate(['/entrar']);
    });
  }

  verificaOrganograma(): Promise<any> {
    return new Promise((resolve) => {
      if(this.perfilAcesso == 0) {
        resolve(false); 
        return 
      }
      this.organogramaService.getTodosOrganogramas(this.userInfo['usuario'].codigo).subscribe(res => {
        for(const organogramas of res['resultado']) {
          for(const organograma of organogramas) {
            if(organograma['checked'] == true) {
              resolve(true);
              return
            }
          }
        }
        resolve(false);
      }, error => {
        resolve(false);
      }); 
    });
  }

}
