import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';

@Component({
  selector: 'app-filtrar-usuarios',
  templateUrl: 'filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltrarGerenciarUsuariosComponent implements OnInit {
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateParams: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Input() itemsPerView: number = 10;
  @Output() toggleFilter: EventEmitter<any> = new EventEmitter<any>();

  _itemsPerView: any;
  _perfilAcesso: any;
  

  // @Input()
  // set itemsPerView(value: any) {
  //   this._itemsPerView = value;
  //   // this.search();
  // }

  // get itemsPerView(): any {
  //   return this._itemsPerView;
  // }

  @Input()
  set perfilAcesso(value: any) {
    this._perfilAcesso = value;
    this.search();
  }
  @Input() visible: any;

  get perfilAcesso(): any {
    return this._perfilAcesso;
  }

  labels: any;
  filter = '';
  searchForm: FormGroup;
  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  block: boolean = false;

  // searchParams: Object = {
  //   pagina: 1
  // };
  errorMessage = '';
  // data: Object = {};
  loading: boolean = false;
  debounce: Subject<string> = new Subject<string>();
  situacao = 'Situação';
  perfil = 'Perfil de Acesso';
  perfilAtivado: boolean = false;
  searchParams = {};
  data: Object = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  // visible = '';
  ativo: number = null;
  // Perfil: number = null;
  usuarios: Array<any> = [];
  situacaoAtivada = false;

  filterCurrent: Object = {
    total_pagina: 10,
    pagina: 1,
  }

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private gerenciarUsuariosService: GerenciarUsuariosService
  ) {
    this.criarFormulario();
    this.setLabels();
  }

  ngOnInit() {
    this.gerenciarUsuariosService
      .obterUsuarios(this.filterCurrent)
      .subscribe(res => {
        console.log('res lista user: ', res)
        this.loading = false;
      },
    error => (this.errorMessage = error));
    this.configurarSubject();
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  statusFilter(e: any) {
    const value = e.target.name;
    console.log('setando valor :: ', value);
    this.situacao = value;
    this.ativo = e.target.value;
    // this.situacaoAtivada = true;
  }

  statusFilterProfile(e: any) {
    const value = e.target.name;
    console.log('setando valor :: ', value);
    this.perfil = value;
    this.filter = e.target.value;
  }

  onSearchByStatus() {
    this.searchParams['situacao'] = this.ativo;
    this.searchParams['pagina'] = 1;
    this.situacaoAtivada = true;
    this.search();
    this.visible = '';
  }

  onSearchByProfile():void {
    this.searchParams['codigoPerfilAcesso'] = this.filter;
    this.searchParams['pagina'] = 1;
    this.perfilAtivado = true;
    this.search();
    this.visible = '';
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)])
      ]
    });
  }

  alterarIdentificador(tipo: string) {
    this.searchForm.controls.numero.setValue('');

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.searchForm.controls.numero.setValidators(Validators.minLength(8));
        this.searchForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '00.000.000/0000-00';
        this.searchForm.controls.numero.setValidators(Validators.minLength(8));
        this.searchForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.searchForm.controls.numero.setValidators(Validators.minLength(1));
        this.searchForm.controls.numero.updateValueAndValidity();
        break;
      case 'nomeRazaoSocial':
        this.searchForm.controls.numero.setValidators(Validators.minLength(1));
        this.searchForm.controls.numero.updateValueAndValidity();
        break;
    }

    delete this.searchParams['cpfCnpj'];
    delete this.searchParams['inscricao'];
    delete this.searchParams['nomeRazaoSocial'];
  }

  setSearch() {
    this.searchParams['pagina'] = 1;
    this.data['paginaAtual'] = 1;
    this.search();
    this.block=false;
  }

  search() {
    this.loading = true;
    const formValue = this.searchForm.value;
    if (this.searchParams["situacao"] === null) {
      delete this.searchParams["situacao"];
    }
    if (!this.searchParams["situacao"]) {
      this.situacao = "Situação";
      this.ativo = null;
    }

    if (this.searchParams["codigoPerfilAcesso"] === null) {
      delete this.searchParams["codigoPerfilAcesso"];
    }
  
    if (!this.searchParams["codigoPerfilAcesso"]) {
      this.perfil = "Perfil de Acesso";
      this.filter = null;
    } 

    if (formValue.tipo === 'cpf' || formValue.tipo === 'cnpj') {
      this.searchParams['cpfCnpj'] = formValue.numero;
      if (formValue.numero === '' || typeof formValue.numero === 'undefined') {
        delete this.searchParams['cpfCnpj'];
      }
    } else {
      this.searchParams[formValue.tipo] = formValue.numero;
      if (formValue.numero === '') {
        delete this.searchParams[formValue.tipo];
      }
    }
    this.searchParams['pagina'] = this.filterCurrent['pagina']; 
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.onDataChange.emit(true);
    this.updateParams.emit(this.searchParams);

    this.visible = '';
    this.loading = true;
    
    this.gerenciarUsuariosService
      .obterUsuarios(this.searchParams)
      .subscribe(res => {
        this.loading = false;
        this.data = res;
        this.onDataChange.emit(false);
      });
  }

  limparFiltros(item) {
    if (item === 'situacao') {
      this.onFilter.emit('');
      this.situacao = 'Situação';
      this.ativo = null;
      this.situacaoAtivada = false;
      delete this.searchParams['situacao'];
    } 
    else if (item === 'perfil') {
      this.onFilter.emit('');
      this.perfil = 'Perfil de acesso';
      this.filter = null;
      this.perfilAtivado = false;
      delete this.searchParams['codigoPerfilAcesso'];
    } 
    
    this.searchParams['pagina'] = this.filterCurrent['pagina']; 
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.gerenciarUsuariosService.obterUsuarios(this.searchParams).subscribe(data => {
      this.loading = false;
      this.data = data;
    });
  }  

  numeroChange($event) {
    if ($event !== undefined && $event.length == 0) {
      setTimeout(() => {
        this.setSearch();
      }, 150);
    }
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }

  previous() {
    if (!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  blockBtn() {
    this.block = true;
  }

  configurarSubject() {
    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        // this.filter = filter;
        this.setSearch()
      })
  }

  setLabels() {
    this.labels = {
      todos: this.translate.instant('GLOBAIS.TODOS'),
      contribuinte: this.translate.instant('GLOBAIS.CONTRIBUINTE'),
      procurador: this.translate.instant('GLOBAIS.PROCURADOR'),
      admPlataforma: this.translate.instant('GLOBAIS.ADMINISTRADORPLATAFORMA'),
      auditor: this.translate.instant('GLOBAIS.AUDITORFISCAL')
    };
  }
}
