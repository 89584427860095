import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-editar-organograma',
  templateUrl: './editar-organograma.component.html',
  styleUrls: ['./editar-organograma.component.scss']
})

export class EditarOrganogramaComponent implements OnInit, AfterViewInit {
  @ViewChild('myTree', { static: true }) myTree: jqxTreeComponent;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private organogramaService: OrganogramaService) {
  }
  resultado: Array<any> = [];
  filhos: Array<any> = [];
  encontrado: Array<any> = [];
  statusOrganograma: string;
  routeSubscription: Subscription;
  codigoOrganograma: number;
  obj: any = {};
  loading: boolean = false;
  dataFormatada: any;
  editarOrganogramaForm = this.formBuilder.group({
    nome: '',
    novoItem: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(60)])],
    dataCriacao: '',
    ativo: true
  });

  ngOnInit(): void {
    this.loading = true;
    this.routeSubscription = this.route.params.subscribe(params => {
      this.codigoOrganograma = params['id'];
      this.organogramaService.getOrganogramaData(this.codigoOrganograma)
        .subscribe(data => {
          this.dataFormatada = data['resultado'][0]['dataCriacao'];
          this.dataFormatada = moment(this.dataFormatada).format('DD/MM/YYYY');
          
          this.editarOrganogramaForm.patchValue({
            nome: data['resultado'][0]['text'],
            dataCriacao: this.dataFormatada,
            ativo: data['resultado'][0]['ativo']
          })
        })
    })
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  data: any[] = [];
  source = {
    datatype: 'json',
    datafields: [
      { name: 'id' },
      { name: 'parentId' },
      { name: 'text' }
    ],
    id: 'id',
    localdata: null
  };

  dataAdapter = new jqx.dataAdapter(this.source);

  records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);

  AddOnClick(): void {
    let selectedItem = this.myTree.getSelectedItem();
    if (selectedItem == null && this.data.length == 0) {
      this.myTree.addTo({ label: this.editarOrganogramaForm.controls.novoItem.value, id: this.data.length + 1 }, null);
      this.data.push({ 'id': this.data.length + 1, 'text': this.editarOrganogramaForm.controls.novoItem.value, 'parentId': null });
      this.myTree.render();
    }
    else if (selectedItem != null && this.editarOrganogramaForm.controls.novoItem.value.length >= 5) {
      this.myTree.addTo({ label: this.editarOrganogramaForm.controls.novoItem.value, id: this.data.length + 1 }, selectedItem.element);
      this.data.push({ 'id': this.data.length + 1, 'text': this.editarOrganogramaForm.controls.novoItem.value, 'parentId': selectedItem['id'] });
      this.myTree.render();
    } else if (selectedItem == null) {
      Swal.fire({
        title: 'Ops!',
        text: 'Selecione um item antes de adicionar',
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    } else if (this.editarOrganogramaForm.controls.novoItem.value.length <= 5) {
      Swal.fire({
        title: 'Ops!',
        text: 'Nome do item deve ter mais de 5 letras',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  RemoveOnClick(): void {
    let selectedItem = this.myTree.getSelectedItem();
    if (selectedItem != null) {
      this.resultado = this.data.filter(element => element.parentId == selectedItem.element.id);
      let elemento = this.data.filter(element => element.id == selectedItem.element.id);
      for (let i = 0; i < this.data.length; i++) {
        if (elemento[0].id == this.data[i].id) {
          this.data.splice(i, 1);
        }
      }
      if (this.resultado) {
        this.filhos = this.filhos.concat(this.resultado);
        this.resultado = [];
        for (let i = 0; i < this.filhos.length; i++) {
          this.resultado = this.data.filter(element => element.parentId == this.filhos[i].id);
          this.filhos = this.filhos.concat(this.resultado);
          this.resultado = [];
        }
        for (let i = 0; i < this.filhos.length; i++) {
          for (let j = 0; j < this.data.length; j++) {
            if (this.filhos[i].id == this.data[j].id) {
              this.data.splice(j, 1);
            }
          }
        }
        this.resultado = [];
        this.filhos = [];
      }

      this.myTree.removeItem(selectedItem.element);
    }
  }

  ExpandAllOnClick(): void {
    this.myTree.expandAll();
  }

  CollapseOnClick(): void {
    this.myTree.collapseAll();
  }

  dragStart(item): boolean {
    return true;
  }

  dragEnd(item, dropItem, args, dropPosition, tree): boolean {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].id == item.owner.owner.selectedItem.id) {
        this.data[i].parentId = item.owner.owner.selectedItem.parentId;
      }
    }

    return true;
  }
  
  ajuda() {
    Swal.fire({
      text: 'Digite o nome do item a ser criado e selecione um item no organograma para adicionar.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  }

  isFormInvalid() {
    if (this.editarOrganogramaForm.valid) {
      return false;
    } else {
      return true;
    }
  }

  private loadData(): void {
    this.organogramaService.getOrganogramaData(this.codigoOrganograma).subscribe(data => {
      this.data = data['resultado'];
      setTimeout(() => {
        this.source.localdata = data;
        this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
        this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);
        this.myTree.refresh();
        this.loading = false;
      }, 1000)
    });
  }

  desistir() {
    Swal.fire({
      text: 'Tem certeza que deseja desistir? Todos os dados serão perdidos',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(res => {
      if(res.value) {
        this.router.navigate(['/configuracao/ambiente/consultar-organograma/1'])
      }
    });
  }

  salvar() {
    if(this.data.length > 0) {
      this.obj = this.data;
      if(this.editarOrganogramaForm.controls.ativo.value == true) {
        this.organogramaService.editOrganograma(this.obj, this.codigoOrganograma)
          .subscribe(
            res => {
              Swal.fire({
                title: 'Alterações salvas!',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then(() => {
                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
              });
            }, err => {
              Swal.fire({
                title: 'Ops!',
                text: 'Não foi possível editar o Organograma',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            });
      } else {
        this.organogramaService.desativarOrganograma(this.obj, this.codigoOrganograma)
          .subscribe(
            res => {
              Swal.fire({
                title: 'Alterações salvas!',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then(() => {
                this.router.navigate(['/configuracao/ambiente/consultar-organograma/1']);
              });
            }, err => {
              Swal.fire({
                title: 'Ops!',
                text: 'Não foi possível desativar o Organograma',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            })
      }
    } else {
      Swal.fire({
        title: 'Ops!',
        text: 'É preciso pelo menos um item para salvar o Organograma',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(res => {
        this.myTree.addTo({ label: this.editarOrganogramaForm.controls.nome.value, id: this.data.length + 1 }, null);
        this.data.push({ 'id': this.data.length + 1, 'text': this.editarOrganogramaForm.controls.nome.value, 'parentId': null });
        this.myTree.render();
      });
    }
  }
}
