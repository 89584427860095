import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { AplicacoesService } from '../../../../../service/aplicacoes/aplicacoes.service';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import {
  gerenciarSistemasRouterPath,
  graphicCollors,
} from '../../../../../../environments/version';

@Component({
  selector: 'app-consumo-servicos',
  templateUrl: 'consumo-servicos.component.html',
  styleUrls: ['consumo-servicos.component.scss'],
})
export class ConsumoServicosComponent implements OnInit {
  aplicacao: Object = {};
  aplicacoes: Array<any> = [];
  selectedItem: any;
  actions: string = '';
  loading = false;
  itemColor = graphicCollors.baseColor;
  navigationData: any;

  constructor(
    private aplicacaoService: AplicacoesService,
    private location: Location,
    private router: Router,
  ) {
    this.navigationData = this.router.getCurrentNavigation();
  }

  ngOnInit() {

    this.selectedItem = this.aplicacoes.map(() => true);
    window.scrollTo(0, 0);

    if (
      this.navigationData.extras.state instanceof Object &&
      Object.keys(this.navigationData.extras.state).length === 0
    ) {
      this.navigationData.extras.state = null;
    }

    if (!this.navigationData.extras.state) {
      this.router.navigate([`${gerenciarSistemasRouterPath}/`]);
      return;
    }
    this.actions = this.navigationData.extras.state[0];
    this.aplicacao = this.navigationData.extras.state[1];

    this.obterConsumoServicos(this.aplicacao['codigo']);
  }

  obterConsumoServicos(codigo) {
    this.aplicacaoService.obterConsumosServicos(codigo).subscribe(res => {
      let isAppChecked: boolean;
      this.aplicacoes = res.resultado;
      this.aplicacoes.map(aplicacao => {
        isAppChecked = true;
        aplicacao.endpoints.forEach(endpoint => {
          endpoint.checked = this.checkAppConsumo(
            endpoint['codigo'],
            this.aplicacao['consumos'],
          );
          if (endpoint.checked === false) {
            isAppChecked = false;
          }
          aplicacao.checked = isAppChecked;
        });
      });
    });
  }

  checkAppConsumo(codigoEndpoint: string, consumos: Array<any>) {
    const stringConsumos = JSON.stringify(consumos);
    if (!stringConsumos) return false;
    const hasConsumo =
      stringConsumos.indexOf('"codigo":' + codigoEndpoint) !== -1;
    return hasConsumo;
  }

  onSistemasChange(sistema) {
    sistema.checked = !sistema.checked;
    sistema['endpoints'].forEach(element => {
      element.checked = sistema.checked;
    });
  }

  expandirSistema(index: number) {
    if (!this.selectedItem) {
      this.selectedItem = {};
    }
    this.selectedItem[index] = !this.selectedItem[index];
  }

  onEndpointChange(endpoint, sistema) {
    endpoint.checked = !endpoint.checked;

    if (!endpoint.checked) {
      sistema.checked = false;
    } else {
      let isAllChecked: boolean = true;
      sistema.endpoints.forEach(element => {
        if (!element.checked) {
          isAllChecked = false;
        }
      });
      sistema.checked = isAllChecked;
    }
  }

  onSubmit() {
    this.aplicacao['consumos'] = [];
    this.aplicacoes.forEach(apps => {
      apps.endpoints.forEach(endpoints => {
        if (endpoints.checked) {
          this.aplicacao['consumos'].push(endpoints);
        }
      });
    });

    if (
      this.aplicacao['funcionalidades'] !== null &&
      this.aplicacao['funcionalidades'].length === 0
    ) {
      this.aplicacao['funcionalidades'] = null;
    }

    if (
      this.aplicacao['consumos'] !== null &&
      this.aplicacao['consumos'].length === 0
    ) {
      this.aplicacao['consumos'] = null;
    }

    if (
      this.aplicacao['endpoints'] !== null &&
      this.aplicacao['endpoints'].length === 0
    ) {
      this.aplicacao['endpoints'] = null;
    }

    if (this.actions === 'editar') {
      this.aplicacaoService
        .editaAplicacao(this.aplicacao['codigo'], this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    } else {
      this.aplicacaoService
        .criaAplicacao(this.aplicacao)
        .subscribe(
          res => this.handleResponse(),
          error => this.handleError(error),
        );
    }
  }

  handleResponse() {
    // Swal.fire('Sucesso!', 'Consumo de serviços criados com sucesso', 'success');
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Consumo de serviços criados com sucesso',
        'ok',
        null,
      ),
    );
    this.router.navigate([`${gerenciarSistemasRouterPath}`]);
  }

  handleError(error) {
    this.loading = false;
    // Swal.fire(
    //   'Ops!',
    //   'Ocorreu um erro e o consumo da aplicação não pôde ser criada.',
    //   'error',
    // );
    Swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Ocorreu um erro e o consumo da aplicação não pôde ser criada.',
        'ok',
        null,
      ),
    );
  }

  desistir() {
    this.router.navigateByUrl(`${gerenciarSistemasRouterPath}`, {state: [this.actions, this.aplicacao]});
  }
}
