import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from '../../../../../../../node_modules/rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ManualService } from 'src/app/service/manual/manual.service';

@Component({
  selector: "app-manuais-usuario",
  templateUrl: "./manuais-usuario.component.html",
  styleUrls: ["./manuais-usuario.component.scss"],
})
export class ManuaisUsuarioComponent implements OnInit {

  loading: boolean = false;
  manualAuditor: any = null;
  manualContribuinte: any = null;
  manualContribuinteInserido: boolean = false;
  manualAuditorInserido: boolean = false;
  nomeManualAuditor: string = null;
  nomeManualContribuinte: string = null;
  auditorSucesso: boolean = false;
  contribuinteSucesso: boolean = false;
  routeSub: Subscription;
  idPerfilAcesso: number = null;
  exluirManualContribuinte: boolean = false
  exluirManualGestor: boolean = false
  adicionarManualContribuinte: boolean = false
  adicionarManualGestor: boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manualService: ManualService,
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit() {
    if (this.idPerfilAcesso) {
      this.obterDados();
    }
  }

  obterDados() {
    this.loading = true;
    this.manualService.obterManuais(this.idPerfilAcesso).subscribe(
      res => {
        this.loading = false;
        this.nomeManualContribuinte = res['resultado']['0'].nomePortal;
        this.nomeManualAuditor = res['resultado']['0'].nomeAuditor;
      }
    );

    this.manualService.baixarManualAuditor(this.idPerfilAcesso).subscribe(
      res => {
        this.loading = false;
        this.manualAuditor = res;
      },error => {
        this.loading = false;
        console.log(error);
      }
    )

    this.manualService.obterManualPortal(this.idPerfilAcesso).subscribe(
      res => {
        this.loading = false;
        this.manualContribuinte = res;
      },
      error => {
        this.loading = false;
        console.log(error)
      }
    )
  }

  fileChangeInserirManualAuditor(event) {
    this.loading = true;
    this.manualAuditorInserido = true
    let file = {
      anexo: event.target.files[0]
    }
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = evt => {
        this._handleReaderLoaded(evt, file);
      };
    }

    this.nomeManualAuditor = event.target.files[0].name;

  }

  fileChangeInserirManualContribuinte(event) {
    this.loading = true;
    this.manualContribuinteInserido = true;
    let file = {
      anexo: event.target.files[0],
    }
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = evt => {
        this._handleReaderLoaded(evt, file);
      };
    }

    this.nomeManualContribuinte = event.target.files[0].name
  }

  _handleReaderLoaded(evt, file) {
    if (this.manualAuditorInserido == true) {
      this.manualAuditor = file.anexo;
      this.manualAuditorInserido = false;
      this.definiManualAuditor();
    }
    if (this.manualContribuinteInserido == true) {
      this.manualContribuinte = file.anexo;
      this.manualContribuinteInserido = false;
      this.definiManualContribuinte();
    }
  }

  definiManualAuditor() {
    this.adicionarManualGestor = true;
    this.loading = false;
    Swal.fire(
      getSwal(
        'success',
        'Sucesso',
        'Manual definido com sucesso.',
        'ok',
        null
      )
    )
  }

  definiManualContribuinte(){
    // this.nomeManualContribuinte = null;
    // this.manualContribuinte = null;
    this.loading = false;
    this.adicionarManualContribuinte = true;
    Swal.fire(
      getSwal(
        'success',
        'Sucesso',
        'Manual definido com sucesso.',
        'ok',
        null
      )
    )
  }

  removeManualAuditor() {
    this.exluirManualGestor = true;
    this.manualAuditor = null;
    this.nomeManualAuditor = null;
    this.loading = false;

    Swal.fire(
      getSwal(
        'success',
        'Sucesso',
        'Manual Removido com sucesso.',
        'ok',
        null
      )
    )
  }

  removeManualContribuinte() {
    this.manualContribuinte = null;
    this.nomeManualContribuinte = null;
    this.exluirManualContribuinte = true;
    this.loading = false;

    Swal.fire(
      getSwal(
        'success',
        'Sucesso',
        'Manual Removido com sucesso.',
        'ok',
        null
      )
    )
  }

  atualizar() {
    if (this.exluirManualGestor == true) {
      this.manualService.excluirManualAuditor(this.idPerfilAcesso, this.manualAuditor)
      .subscribe(res => {
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
    if (this.adicionarManualGestor = true) {
      this.manualService.inserirManualAuditor(this.idPerfilAcesso, this.manualAuditor)
      .subscribe(res => {
        this.loading = false;
      },error => {
        this.loading = false;
        this.nomeManualAuditor = null;
        this.manualAuditor = null;
      });
    }
    if(this.exluirManualContribuinte = true) {
      this.manualService.excluirManualPortal(this.idPerfilAcesso, this.manualContribuinte)
      .subscribe(res => {
        this.loading = false;
      },error => {
        this.loading = false;
      });
    }
    if (this.adicionarManualContribuinte == true) {
      this.manualService.inserirManualPortal(this.idPerfilAcesso, this.manualContribuinte)
      .subscribe(res => {
        this.loading = false;
      },error => {
        this.loading = false;
      });
    }

    Swal.fire(
      getSwal(
        'success',
        'Sucesso',
        'Alteração realizada com sucesso.',
        'ok',
        null
      )
    ).then(res => {
      this.router.navigate(['configuracao/ambiente']);
    });
  }

  ajuda(valor) {
    let msg1 =
      `
      <p> Neste campo você deve inserir o Manual de Usuário que ficará disponível no portal do Contribuinte.<p>
    `;

    let msg2 =
      `
      <p>Neste campo você deve inserir o Manual de Usuário que ficará disponível no portal do Gestor.<p>
    `;

    switch (valor) {
      case 'manualPortalContribuinte': {
        Swal.fire({
          icon: 'info',
          html: msg1,
          confirmButtonText: 'Ok',
        })
        break;
      }

      case 'manualPortalGestor': {
        Swal.fire({
          icon: 'info',
          html: msg2,
          confirmButtonText: 'Ok',

        })
        break;
      }
    }
  }

}
