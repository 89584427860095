import { SelectorMatcher } from '@angular/compiler';
import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-filtro-contribuinte',
  templateUrl: 'filtro-contribuinte.component.html',
  styleUrls: ['./filtro-contribuinte.component.scss'],
})
export class FiltroContribuinteComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  contribuinteForm: FormGroup;
  @Input()
  visible: string;
  contribuinte: boolean = false;
  numero: any;
  loading: boolean = false;

  searchParams: Object = {
    pagina: 1
  };
  
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  constructor(private fb: FormBuilder) {}
  
  ngAfterViewChecked(): void {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));
    
    if(sessionStorage.getItem('filtro') && (sessaoFiltro.cpf_cnpj || sessaoFiltro.inscricao)){
      let numero = sessaoFiltro.cpf_cnpj ? sessaoFiltro.cpf_cnpj : sessaoFiltro.inscricao;
      this.contribuinte =  true;
      this.contribuinteForm.get('numero').setValue(numero);
    } else {
      this.contribuinte = false;
    }
  }

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.contribuinteForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
    });
  }

  alterarIdentificador(tipo: string) {
    this.contribuinteForm.controls.numero.reset();

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(11),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '00.000.000/0000-00';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(8),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(1),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
    }
    this.contribuinteForm.controls.numero.setValue('');
  }

  limparFiltros() {
    this.resetarParametros()
    if (
      !this.contribuinteForm.controls.numero.value ||
      this.contribuinteForm.controls.numero.value.toString() < 2
    ) {
      // let obj = {};
      // this.onFilter.emit(obj);
      // this.onPageUpdate.emit(1);
      return;
    }
    const tipos =
      this.contribuinteForm.controls.tipo.value === 'inscricao'
        ? 'inscricao'
        : 'cpf_cnpj';
    this.onClear.emit({
      tipo: tipos,
      numero: this.contribuinteForm.controls.numero.value,
    });
    var obj = {};
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['paginaAtual'] = 1

    this.contribuinte = false;
    this.contribuinteForm.reset();
    this.searchParams['pagina'] = 1;
  }

  pesquisarContribuinte() {
    this.loading = true;
    this.contribuinte = true;
    const value = this.contribuinteForm.value;
    let obj = {};
    if (value.tipo === 'inscricao') {
      obj['inscricao'] = value.numero;
      obj['cpf_cnpj'] = '';
    } else {
      obj['inscricao'] = '';
      obj['cpf_cnpj'] = value.numero;
    }

    if (
      obj['inscricao'].toString().length < 2 &&
      obj['cpf_cnpj'].toString().length < 2
    ) {
      this.contribuinte = false;
    }

    this.onFilter.emit(obj);
    this.loading = true;

    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['pagina'] = 1
  }

  resetarParametros() {
    this.searchParams['pagina'] = 1;
    this.tipo = 'cpf';
    this.numero = null;

    let obj = {}
    this.onFilter.emit(obj);
    this.onPageUpdate.emit(1);
    this.data['paginaAtual'] = 1
  }
}
